<template>
    <div class="PopupConfirmed" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 입소 등록 </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="20%">
                            <col width="80%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">연번</th>
                            <td class="text-left">{{ data.f_num }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ data.f_patient_name }}</td>
                        </tr>
                        <tr>
                            <th class="field">생년월일</th>
                            <td class="text-left">{{$moment(data.f_patient_birth).format('YYYY-MM-DD') + '('+data.f_patient_sex +' / ' + data.f_patient_age +')' }}</td>
                        </tr>
                        <tr>
                            <th class="field">입소일</th>
                            <td class="text-left">
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :disabled-dates="disabledDates" v-model="f_transfer_date"></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    import datepicker from 'vuejs-datepicker';
    import {ko} from 'vuejs-datepicker/dist/locale'

    let minDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    minDate.setDate(minDate.getDate() - 90);
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);

    export default {
        name: 'PopupConfirmed',
        data() {
            return {
                disabledDates: {
                    to: minDate,
                    from: maxDate
                },
                ko : ko,
                isVisible:false,
                data:[],
                f_transfer_date : new Date(),       // 입소일
                page:'',                            // 페이지
            }
        },
        components: {  datepicker },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, page){
                this.isVisible=isVisible;
                this.data = obj;
                this.page = page;
            },
            sendFunc(){
                if (!this.f_transfer_date){
                    this.showAlert('', '입소일을 선택해 주세요.');
                    return false;
                }

                let formData = new FormData();
                formData.append('api_name', 'patientStateChange');  // API 이름
                formData.append('f_idx', this.data.f_idx);	                    // 확진자 기본키
                formData.append('f_chang_code', 'G0904');	        // 상태변경 분류값
                formData.append('f_chang_value', this.$moment(this.f_transfer_date).format('YYYY-MM-DD'));	        // 상태변경 분류값
                formData.append('f_admin_id', this.userDataAdminId);

                this.ajaxPost('', formData,'sendFuncResult');
            },
            sendFuncResult(response) {
                this.showAlert('', '저장 되었습니다.',"close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
