<template>
    <div class="ConfirmedListManager">
        <main-header menuActive="confirmed" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>배정자관리</h2>
                </div>
                <div class="row searchBtn">
                    <div class="link_tab pull-left">
                        <search-tab :searchTabData.sync="sort_data" :searchTabVal.sync="sort_key" />
                    </div>
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>

                <div class="row">
                    <div class="subHead">
                        <h3 style="margin-bottom: 12px;"># {{ statusData.ffasilityName }}
                            <span style="color: darkred; font-size: 12px;">병상현황</span>
                            <div class="pull-right">
                                <a class="btn10" @click="popupConditionFunc()">수정하기</a> &nbsp;
                            </div>
                        </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">총병상</th>
                            <th class="field">사용중</th>
                            <th class="field">입실불가능</th>
                            <th class="field">입실가능</th>
                            <th class="field">입실대기</th>
                        </tr>
                        <tr>
                            <td class="field text-center">{{ statusData.ftotalRoom | comma }} 명</td>
                            <td class="field text-center">{{ statusData.fuseRoom | comma }} 명</td>
                            <td class="field text-center">{{ statusData.fimposibleRoom | comma }} 명</td>
                            <td class="field text-center">{{ statusData.fposibleRoom | comma }} 명</td>
                            <td class="field text-center">{{ statusData.fwaitRoom | comma }} 명</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">접수일</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" v-model="sch_reg_start_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" v-model="sch_reg_end_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>


                            </div>

                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">확진일</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" v-model="sch_confirm_start_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" v-model="sch_confirm_end_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>

                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">배정일</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" v-model="sch_assign_start_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" v-model="sch_assign_end_date"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row pdd-top-10">
                            <div class="searchBox">
                                <!-- 검색키워드 -->
                                <div class="SearchConditionCombo inline-block">
                                  <label class="searchTitle">키워드</label>
                                  <select class="select_ansimi width150" v-model="searchConditionCombo">
                                    <option v-for="(data, index) in searchConditionConfirmedList" :key="index" :value="data.code">{{data.name}}</option>
                                  </select>
                                  <input type="text" class="input type2 width300 mrg-left-10" v-model="sch_keyword" v-on:keyup.enter="searchFunc" />
                                </div>
                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchFunc">조회</a>
                            <a class="btn8" @click="reset">초기화</a>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <colgroup>
                                <!--								<col width="100" />-->
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="100" />
                                <col width="180" />
                                <col width="180" />
                                <col width="180" />
                                <col />
                            </colgroup>
                            <thead>
                            <tr>
                                <!--								<th>-->
                                <!--									<input type="checkbox" />-->
                                <!--								</th>-->
                                <th>연번</th>
                                <th>접수일</th>
                                <th>확진일</th>
                                <th>지역</th>
                                <th>이름</th>
                                <th>성별</th>
                                <th>나이</th>
                                <th>생년월일</th>

                                <th>중증도</th>
                                <!--<th>중증상태</th>-->
                                <th>기저질환</th>
                                <th>배정일</th>
                                <th>입소일</th>
                                <th>퇴소일</th>
                                <th>최종상태</th>
                                <th>관리</th>
                            </tr>
                            </thead>
                            <tbody v-if="dataList.length>0">
                            <tr v-for="(data, index) in dataList" :key="index">
                                <!--<td>{{parseInt(totalCount) - ((parseInt(page)-1)*20 + (parseInt(index)) ) }}</td> -->
                                <td>{{ data.f_idx }}</td>
                                <td>{{ (data.f_reg_date != '0000-00-00 00:00:00') ? $moment(data.f_reg_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_confirm_date != '0000-00-00 00:00:00') ? $moment(data.f_confirm_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_region }}</td>
                                <td><a @click="popupPatientFunc(data)" class="text-link">{{ data.f_patient_name }}</a></td>
                                <td>{{ data.f_patient_sex }}</td>
                                <td>{{ data.f_patient_age }}</td>
                                <td>{{ data.f_patient_birth }}</td>

                                <td>{{ data.f_symptom_level }}</td>
                                <!--<td>{{ data.f_symptom_type }}</td>-->
                                <td>{{ data.f_disease_info.trim().endsWith(",") ? data.f_disease_info.trim().slice(0, -1) : data.f_disease_info }}</td>
                                <td>{{ (data.f_assign_date != '0000-00-00 00:00:00') ? $moment(data.f_assign_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_transfer_date != '0000-00-00 00:00:00') ? $moment(data.f_transfer_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ (data.f_result_date != '0000-00-00 00:00:00') ? $moment(data.f_result_date).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.f_result }}</td>
                                <td>
                                    <!--<button type="button" class="btn btn-sm btn-info mrg-right-5" @click="confirmedEnterFunc(data)" v-if="data.f_assign_state_code !='G0303'">
                                        입실
                                    </button>-->
                                    <button type="button" class="btn btn-sm btn-danger mrg-right-5" @click="popupUnderlyingDiseaseFunc(data)">
                                        기저질환
                                    </button>
                                    <button type="button" class="btn btn-sm btn-info mrg-right-5" @click="popupSeverityFunc(data)">
                                      중증도
                                    </button>
                                    <button type="button" class="btn btn-sm btn-inverse mrg-right-5" @click="popupConfirmedTransferredFunc(data)">
                                        전원
                                    </button>
                                    <button type="button" class="btn btn-sm btn-primary mrg-right-5" @click="confirmedExitFunc(data)">
                                        최종결과
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="15">검색결과가 존재 하지 않습니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <paginate v-model="page" :page-count="totalPage" :prev-text="'Prev'" :next-text="'Next'" :containerClass="'pagination'" :click-handler="changePage"></paginate>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />

        <!-- 병상현황 팝업 -->
        <popup-condition ref="popupCondition" />

        <!-- 증상변경, 상태변경, 팝업 -->
        <popup-mobile-combo ref="popupMobileCombo" />

        <!-- 기절질환 팝업 -->
        <popup-underlying-disease ref="popupUnderlyingDisease" />

        <!-- 전원등록 팝업 -->
        <popup-confirmed-transferred ref="popupConfirmedTransferred" />

        <!-- 중증도 팝업 -->
        <popup-severity ref="popupSeverity" />

        <!-- 퇴원/퇴실 팝업 -->
        <popup-confirmed-exit ref="popupConfirmedExit" />

        <!-- 입실 팝업 -->
        <popup-confirmed-in ref="popupConfirmedIn" />

        <!-- 환자정보 팝업 -->
        <popup-patient ref="popupPatient" />

    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';

import searchTab from '@/components/SearchTab';
import datepicker from 'vuejs-datepicker';
//import searchConditionBox from '@/components/SearchConditionBox';
import Paginate from 'vuejs-paginate'

import popupCondition from '@/components/PopupCondition';
import popupMobileCombo from '@/components/PopupMobileCombo';
import popupUnderlyingDisease from '@/components/PopupUnderlyingDisease';
import popupConfirmedTransferred from '@/components/PopupConfirmedTransferred.vue';
import popupSeverity from '@/components/PopupSeverity.vue';
import popupConfirmedExit from '@/components/PopupConfirmedExit.vue';
import popupConfirmedIn from '@/components/PopupConfirmedIn.vue';
import PopupPatient from "@/components/PopupPatient";

export default {
    name: 'ConfirmedListManager',
    data() {
        return {
            sch_reg_start_date:'',   // 접수일시작
            sch_reg_end_date:'',   // 접수일끝
            sch_confirm_start_date:'',   // 확진일시작
            sch_confirm_end_date:'',   // 확진일끝
            sch_assign_start_date:'',   // 배정일시작
            sch_assign_end_date:'',   // 배정일끝

            sort_key : '',        // Tab 정보
            sort_data : [
                { code:'', name:'전체' },
                { code:'G0302', name:'입실대기' },
                { code:'G0303', name:'입소중' },
                { code:'G0308', name:'완치 (퇴소/퇴원)' },
                { code:'G0304', name:'전원' },
            ],

            searchConditionCombo : 'name',
            sch_keyword:'',

            list_fasility:[],
            list_fasility_popup : [],           // 시설별 리스트
            list_move_reason : [],             // 배정상태 리스트

            list_symptom_state:[],

            list_result_popup : [],           // 시설별 리스트


            page:1,
            totalPage:0,
            totalCount:0,
            dataList:[],
            statusData:{
            },
        }
    },
    components: { PopupPatient,mainHeader, mainFooter, searchTab, datepicker, Paginate, popupCondition, popupMobileCombo, popupUnderlyingDisease, popupConfirmedTransferred, popupSeverity, popupConfirmedExit ,popupConfirmedIn},
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
        sort_key(){ this.searchFunc() }
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
       if (this.$route.params.info){
            this.sch_reg_start_date = this.$route.params.sch_reg_start_date;
            this.sch_reg_end_date = this.$route.params.sch_reg_end_date;
            this.sch_confirm_start_date = this.$route.params.sch_confirm_start_date;
            this.sch_confirm_end_date = this.$route.params.sch_confirm_end_date;
            this.sch_assign_start_date = this.$route.params.sch_assign_start_date;
            this.sch_assign_end_date = this.$route.params.sch_assign_end_date;
            this.sch_assign_type = this.$route.params.sch_assign_type;
            this.sch_fasility = this.$route.params.sch_fasility;
            this.sch_region = this.$route.params.sch_region;
            this.sch_sex = this.$route.params.sch_sex;
            this.sch_result = this.$route.params.sch_result;
            this.sch_admin = this.$route.params.sch_admin;
            this.sch_unassign_reason = this.$route.params.sch_unassign_reason;
            this.searchConditionCombo = this.$route.params.searchConditionCombo;
            this.sch_keyword = this.$route.params.sch_keyword;
            this.sort_key = this.$route.params.sort_key;
            this.page = this.$route.params.page;

            this.listFasilitySetFunc()
            this.searchListFunc(this.page)
        }else{
            this.reset();
        }
    },
    methods: {
        init(){
            //let formData = new FormData();
            //formData.append('api_name', 'patientCreateCode');
            //this.ajaxPost('', formData,'initResult');

            this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        },
        initResult(response){
            let result = response;

            // 미배정사유별
            this.list_symptom_state = result.symptomStateList;

            // 시설별, 시설별 팝업
            let vm = this;
            result.fasilityList.forEach(function(obj){
                vm.list_fasility.push(obj)
                vm.list_fasility_popup.push(obj)
            });
            this.list_fasility.unshift({ code:'', name:'선택' });
            this.list_fasility_popup.unshift({ code:'', name:'선택' });

            // 전원사유
            this.list_move_reason = result.moveReasonList;
            this.list_move_reason.unshift({ code:'', name:'선택' });

            // 최종결과
            this.list_result = result.resultList;
            this.list_result_popup.unshift({ code:'', name:'선택' });

            this.searchFunc();
        },
        // 초기화
        reset(){
            this.sch_reg_start_date = '';
            this.sch_reg_end_date = '';
            this.sch_confirm_start_date = '';
            this.sch_confirm_end_date = '';
            this.sch_assign_start_date = '';
            this.sch_assign_end_date = '';

            this.sch_keyword = '';
            this.sort_key = '';
            this.page = 1;

            this.searchFunc();
        },
        searchFunc(){
            this.searchListFunc(1)
        },
        // 리스트 검색
        searchListFunc(pageIndex){

             if (this.sch_reg_start_date && !this.sch_reg_end_date){
               this.showAlert('', '접수 시작일자를 선택해 주세요.');
               return false;
             }

             if (this.sch_reg_start_date && !this.sch_reg_end_date){
               this.showAlert('', '접수 종료일자를 선택해 주세요.');
               return false;
             }

             let sch_reg_start_date = (this.sch_reg_start_date) ? this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
             let sch_reg_end_date = (this.sch_reg_end_date) ? this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

             if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
               this.showAlert('', '확진 시작일자를 선택해 주세요.');
               return false;
             }

             if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
               this.showAlert('', '확진 종료일자를 선택해 주세요.');
               return false;
             }

             let sch_confirm_start_date = (this.sch_confirm_start_date) ? this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
             let sch_confirm_end_date = (this.sch_confirm_end_date) ? this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

             if (this.sch_assign_start_date && !this.sch_assign_end_date){
               this.showAlert('', '확진 시작일자를 선택해 주세요.');
               return false;
             }

             if (this.sch_assign_start_date && !this.sch_assign_end_date){
               this.showAlert('', '확진 종료일자를 선택해 주세요.');
               return false;
             }

             let sch_assign_start_date = (this.sch_assign_start_date) ? this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
             let sch_assign_end_date = (this.sch_assign_end_date) ? this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            /*
            let formData = new FormData();
            formData.append('api_name', 'patientMobileList');         // API 이름
            formData.append('sch_reg_start_date', sch_reg_start_date);	        // 접수일시작
            formData.append('sch_reg_end_date', sch_reg_end_date);	            // 접수일끝
            formData.append('sch_confirm_start_date', sch_confirm_start_date);	// 확진일시작
            formData.append('sch_confirm_end_date', sch_confirm_end_date);	    // 확진일끝
            formData.append('sch_assign_start_date', sch_assign_start_date);	    // 배정일시작
            formData.append('sch_assign_end_date', sch_assign_end_date);	        // 배정일끝
            formData.append('sch_keyword', this.sch_keyword);	        // 키워드
            formData.append('sch_keyword_type', this.searchConditionCombo);	        // 키워드
            formData.append('sort_key', this.sort_key);	                        // Tab
            formData.append('page_num', pageIndex);	                // 현재 페이지 번호
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
            */

            let params = `{
                            "pagination": {
                              "curPage": "${pageIndex}",
                              "pageScale": 20
                            },
                            "search": {
                              "fromRegDt": "${sch_reg_start_date}",
                              "toRegDt": "${sch_reg_end_date}",
                              "fromCfmDt": "${sch_confirm_start_date}",
                              "toCfmDt": "${sch_confirm_end_date}",
                              "fromAssDt": "${sch_assign_start_date}",
                              "toAssDt": "${sch_assign_end_date}",
                              "tapkey": "${this.sort_key}",
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"
                            },

                            "adminId": "${this.userDataAdminId}"

            }`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocAssign/listAssign?params=' + queryString, null, 'searchListFuncResult');
        },
        searchListFuncResult(response) {
           /*
           let result = response.result_data;
            this.dataList = result.list_data;
            this.statusData = result.status_data;
            if (result.total_cnt == 0){
                this.totalPage = 0;
                this.totalCount=0;
            }else{
                this.totalPage = parseInt(result.total_page);
                this.totalCount = parseInt(result.total_cnt);
            }
            */
            let result = response;
            this.dataList = [];
            this.statusData = result.statusList;
            let tmpMap;
            let tmpMaps;

            // 리스트
            result.list.map(el => {
                tmpMap = {};
                tmpMap.f_idx = el.seq;
                tmpMap.f_date_num = el.num;
                tmpMap.f_reg_date = el.registDt;
                tmpMap.f_confirm_date = el.cfmDt;
                tmpMap.f_region = el.region;
                tmpMap.f_patient_name = el.name;
                tmpMap.f_patient_sex = el.sex;
                tmpMap.f_patient_age = el.age;
                tmpMap.f_patient_birth = el.birth;
                tmpMap.f_symptom_level = el.symptomLevel;
                tmpMap.f_disease_info = el.symptomType;
                tmpMap.f_assign_date = el.assignDate;
                tmpMap.f_transfer_date = el.transferDate;
                tmpMap.f_result_date = el.resultDate;
                tmpMap.f_result = el.fanalResult;
                tmpMap.f_fasility = el.fasility;

                this.dataList.push(tmpMap);
            });
            if (result.count == 0){
                this.totalPage = 0;
                this.totalCount = 0;
            }else{
                this.totalPage = parseInt(result.pagination.maxPage);
                this.totalCount = parseInt(result.count);
            }


        },
        // 페이지 변경
        changePage(){
            this.searchListFunc(this.page)
        },
        // 입실처리
        confirmedEnterFunc(obj){
          this.$refs.popupConfirmedIn.init(true, obj, this.page);
        },
        // 퇴실처리
        confirmedExitFunc(obj){
            this.$refs.popupConfirmedExit.init(true, obj,this.list_result , this.page)
        },
        // 삭제 처리
        commonConfirm(formData){
            this.ajaxPost('', formData,'commonConfirmResult');
        },
        // 삭제 처리 결과
        commonConfirmResult(response) {
            this.showAlert('', response.result_msg, "searchListFunc", 1);
        },
        // 병상현황 팝업
        popupConditionFunc(){
            this.$refs.popupCondition.init(true, this.statusData, this.page)
        },
        // 병상현황 팝업
        popupMobileFunc(obj, list, mode){
            this.$refs.popupMobileCombo.init(true, obj, list, mode, this.page)
        },
        // 기절질환 등록 팝업
        popupUnderlyingDiseaseFunc(obj){
            this.$refs.popupUnderlyingDisease.init(true, obj, this.page)
        },
        // 전원등록
        popupConfirmedTransferredFunc(obj){
            this.$refs.popupConfirmedTransferred.init(true, obj, this.list_fasility_popup, this.list_move_reason, this.page)
        },
        // 중증도 변경
        popupSeverityFunc(obj){
            this.$refs.popupSeverity.init(true, obj, this.list_symptom_state, this.page)
        },
        excelDownFunc(){

            if (this.sch_reg_start_date && !this.sch_reg_end_date){
               this.showAlert('', '접수 시작일자를 선택해 주세요.');
               return false;
            }

            if (this.sch_reg_start_date && !this.sch_reg_end_date){
               this.showAlert('', '접수 종료일자를 선택해 주세요.');
               return false;
            }

            let sch_reg_start_date = (this.sch_reg_start_date) ? this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_reg_end_date = (this.sch_reg_end_date) ? this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
               this.showAlert('', '확진 시작일자를 선택해 주세요.');
               return false;
            }

            if (this.sch_confirm_start_date && !this.sch_confirm_end_date){
               this.showAlert('', '확진 종료일자를 선택해 주세요.');
               return false;
            }

            let sch_confirm_start_date = (this.sch_confirm_start_date) ? this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_confirm_end_date = (this.sch_confirm_end_date) ? this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            if (this.sch_assign_start_date && !this.sch_assign_end_date){
               this.showAlert('', '배정 시작일자를 선택해 주세요.');
               return false;
            }

            if (this.sch_assign_start_date && !this.sch_assign_end_date){
               this.showAlert('', '배정 종료일자를 선택해 주세요.');
               return false;
            }

            let sch_assign_start_date = (this.sch_assign_start_date) ? this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD')+' 00:00:00' : '';
            let sch_assign_end_date = (this.sch_assign_end_date) ? this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD')+' 23:59:59' : '';

            console.log("엑셀다운로드");
           /*
            let url=this.rootUrl+'/_excel/excel_patient_region.php?f_admin_id='+this.userDataAdminId;

          if(this.sch_reg_start_date && ths.sch_reg_end_date){
            url += '&sch_reg_start_date='+this.$moment(this.sch_reg_start_date).format('YYYY-MM-DD');
            url += '&sch_reg_end_date='+this.$moment(this.sch_reg_end_date).format('YYYY-MM-DD');
          }
          if(this.sch_confirm_start_date && this.sch_confirm_end_date){
            url += '&sch_confirm_start_date='+this.$moment(this.sch_confirm_start_date).format('YYYY-MM-DD');
            url += '&sch_confirm_end_date='+this.$moment(this.sch_confirm_end_date).format('YYYY-MM-DD');
          }
          if(this.sch_assign_start_date && this.sch_assign_end_date){
            url += '&sch_assign_start_date='+this.$moment(this.sch_assign_start_date).format('YYYY-MM-DD');
            url += '&sch_assign_end_date='+this.$moment(this.sch_assign_end_date).format('YYYY-MM-DD');
          }

            this.excelExport(url);

            param 들어갈 것들
                              "fromRegDt": "${sch_reg_start_date}",
                              "toRegDt": "${sch_reg_end_date}",
                              "fromCfmDt": "${sch_confirm_start_date}",
                              "toCfmDt": "${sch_confirm_end_date}",
                              "fromAssDt": "${sch_assign_start_date}",
                              "toAssDt": "${sch_assign_end_date}",
                              "tapkey": "${this.sort_key}",
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"


                              "fromRegDt": "2021-01-01",
                              "toRegDt": "2021-01-01",
                              "fromCfmDt": "",
                              "toCfmDt": "",
                              "fromAssDt": "",
                              "toAssDt": "",
                              "tapkey": "",
                              "keyword": "all",
                              "keyword_txt": ""


            */

            let params = `{
                            "search": {
                              "fromRegDt": "${sch_reg_start_date}",
                              "toRegDt": "${sch_reg_end_date}",
                              "fromCfmDt": "${sch_confirm_start_date}",
                              "toCfmDt": "${sch_confirm_end_date}",
                              "fromAssDt": "${sch_assign_start_date}",
                              "toAssDt": "${sch_assign_end_date}",
                              "tapkey": "${this.sort_key}",
                              "keyword": "${this.searchConditionCombo}",
                              "keyword_txt": "${this.sch_keyword}"
                            },

                            "adminId": "${this.userDataAdminId}"
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocAssign/listDownAssign?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            // 엑셀 헤더 추가
            excelData.push(['NO', '연번', '접수연번', '접수일', '확진일', '지역', '이름', '성별', '나이', '생년월일', '중증도', '기저질환','배정일', '입소일', '퇴소일', '최종상태']);

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {
                excelData.push([
                    index + 1,
                    data.연번,
                    data.접수연번,
                    data.접수일,
                    data.확진일,
                    data.지역,
                    data.이름,
                    data.성별,
                    data.나이,
                    data.생년월일,
                    data.중증도,
                    data.기저질환,
                    data.배정일,
                    data.입소일,
                    data.퇴소일,
                    data.최종상태
                ]);
            });

            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정_배정자관리.xlsx');
        },
        // 환자정보 변경
        popupPatientFunc(obj){
          this.$refs.popupPatient.init(true, obj)
        }
    }
}
</script>

<style scoped>
</style>
